<template>
    <div>
        <lineFieldsContainer
                :fieldAttrRow="{}"
                :fields="fields"
                :valueLine="valueLine"
                @field-line-changed="checkEventValue(...arguments)"
        ></lineFieldsContainer>
    </div>
</template>
<script>
   import lineFieldsContainer from "@/commonComponents/lineFieldsContainer.vue";
  export default {
    components: { lineFieldsContainer },
    props: ["result", "field","fieldAttributes"],
    data() {
      return {
        retry: [...Array(10).keys()],
        unit:["bps","kbps","Mbps"],
        action:["stop test","stop step","warning","ignore"]
      };
    },
    computed: {
      valueLine() {
        //console.log(this.fieldAttributes.additional.objName);
        let fieldAttr = this.result.valueLine;
        let givenValue = fieldAttr[this.fieldAttributes.additional.objName];
        if(typeof givenValue!='undefined') {
          return givenValue;
        } else {
          let line = { value:"0",unit: "bps", action: "stop test", period: "10" };
          this.$emit("input", line);
          return line;
        }

      },
      fields() {
        let fields = {
          str1: {
            type: "string",
            string: this.fieldAttributes.additional.string1,
            fieldAttrInput: {
              "class": ""
            },
            colAttrs: {
              "cols": 1
            }
          },
          value: {
            type: "text",
            name: "value",
          },
          unit: {
            type: "select",
            associatedOption: this.unit,
            name: "unit",
          },
          action: {
            type: "select",
            associatedOption: this.action,
            name: "Action",
          },
          str2: {
            type: "string",
            string: this.fieldAttributes.additional.string2,
            fieldAttrInput: {
              "class": "mt-3"
            },
            colAttrs: {
              "cols": 1
            }
          },
          period: {
            type: "text",
            name: "Sec",
          },
        };
        return fields;
      },
    },
    methods: {
      checkEventValue(line) {
        this.$emit("input", line);
      },
    },

  }
</script>
